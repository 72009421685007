*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul, ol {
  padding-inline-start: 30px;
  margin-block-start: 0.3em;
  margin-block-end: 0.6em;
}

html {
  font-size: 62.5%;
  background-size: cover;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fefefe;
  height: 100%;
}

.Toastify__toast {
  border-radius: 0.4rem;
}

.Toastify__toast-body {
  font-size: 1.4rem;
  font-weight: 600;
}

.grecaptcha-badge {
  visibility: hidden;
}
